import React, { lazy, Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import theme from './material-ui/theme';
import setupSentry from './sentry';
import setupI18nextInit from './i18n';
import PrivateRoute from './components/PrivateRoute';
import PrivateRouteAdmin from './components/PrivateRouteAdmin';

import AmberNotification from './components/Common/Snackbar/AmberNotification';
import RedeemCouponDialog from './components/User/RedeemCoupon';
import { setupGoogleAnalytics } from './helpers/googleAnalytics';
import { setupGTM } from './helpers/GTM';
import LoadingPageWhileGettingUserData from './components/LoadingPage';
import parseFeatureToggle from './helpers/featureToggles';

const Login = lazy(() => import('./components/User/Login'));
const Register = lazy(() => import('./components/User/Register'));
const ForgotPassword = lazy(() => import('./components/User/ForgotPassword'));
const EmailValidate = lazy(() => import('./components/User/EmailValidate'));
const ResetPassword = lazy(() => import('./components/User/ResetPassword'));
const LoginFirebase = lazy(() => import('./components/User/LoginFirebase'));

const ManageUsers = lazy(() => import('./components/Admin/Users'));
const AddUser = lazy(() => import('./components/Admin/AddUser'));
const AdminInvoices = lazy(() => import('./components/Admin/Invoices'));
const AdminOrganizationsList = lazy(() =>
  import('./components/Admin/OrganizationsList'),
);
const AdminOrganization = lazy(() => import('./components/Admin/Organization'));
const KalturaTasks = lazy(() => import('./components/Admin/KalturaTasks'));
const AdminUserTranscriptions = lazy(() =>
  import('./components/Admin/UserTranscriptions'),
);
const AdminUserInvoices = lazy(() => import('./components/Admin/UserInvoices'));
const AdminUserDonate = lazy(() => import('./components/Admin/UserDonate'));
const AdminUserTransactions = lazy(() =>
  import('./components/Admin/UserTransactions'),
);
const AdminUserTranscriptionDetail = lazy(() =>
  import('./components/Admin/UserTranscriptionDetail'),
);
const AdminUserProfile = lazy(() => import('./components/Admin/UserProfile'));

const MyFiles = lazy(() => import('./components/User/MyFiles'));
const Account = lazy(() => import('./components/User/Account'));
const Billing = lazy(() => import('./components/User/Billing'));
const Invoices = lazy(() => import('./components/User/Invoices'));
const Organization = lazy(() => import('./components/User/Organization'));
const Plan = lazy(() => import('./components/User/Plan'));
const AppSumoPlan = lazy(() => import('./components/User/AppSumo/plan'));
const Uploads = lazy(() => import('./components/User/Uploads'));
const LoginSAML = lazy(() => import('./components/User/SAML'));
const RegisterSAML = lazy(() => import('./components/User/SAML/registerSaml'));
const Integration = lazy(() => import('./components/Integration'));
const NotFound = lazy(() => import('./components/NotFound'));
const LoginFirebaseSAML = lazy(() => import('./components/User/FirebaseSAML'));
const UploadBeforeSignup = lazy(() =>
  import('./components/User/Uploads/newUploadForm/UploadBeforeSignup'),
);

// SETUP SENTRY
setupSentry();

// INIT i18n
setupI18nextInit();

// INIT Google Tag Manager
setupGTM(window, document, 'script', 'dataLayer', 'GTM-TLJGV9T');

// INIT Google Analytics
setupGoogleAnalytics();

function App() {
  const NEW_SAML_ENABLED = parseFeatureToggle(
    process.env.REACT_APP_FF_NEW_SAML,
  );

  const NEW_LOGIN_ENABLED = parseFeatureToggle(
    process.env.REACT_APP_FF_NEW_LOGIN,
  );

  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AmberNotification />

        <BrowserRouter>
          <Suspense fallback={<LoadingPageWhileGettingUserData />}>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<PrivateRoute component={MyFiles} />} />
              {!NEW_LOGIN_ENABLED && (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="/login_new" element={<LoginFirebase />} />
                </>
              )}
              {NEW_LOGIN_ENABLED && (
                <>
                  <Route path="/login_old" element={<Login />} />
                  <Route path="/login" element={<LoginFirebase />} />
                </>
              )}
              <Route path="/register" element={<Register />} />
              <Route path="/password-reset" element={<ResetPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/emailvalidate" element={<EmailValidate />} />
              <Route path="/upload-register" element={<UploadBeforeSignup />} />
              {!NEW_SAML_ENABLED && (
                <>
                  <Route
                    path="/saml"
                    element={<LoginSAML organisationLogin={false} />}
                  />
                  <Route
                    path="/register-organization-sso"
                    element={<LoginSAML organisationLogin />}
                  />
                  <Route
                    path="/saml_new"
                    element={<LoginFirebaseSAML organisationLogin={false} />}
                  />
                  <Route
                    path="/register-organization-sso_new"
                    element={<LoginFirebaseSAML organisationLogin />}
                  />
                </>
              )}
              {NEW_SAML_ENABLED && (
                <>
                  <Route
                    path="/saml_old"
                    element={<LoginSAML organisationLogin={false} />}
                  />
                  <Route
                    path="/register-organization-sso_old"
                    element={<LoginSAML organisationLogin />}
                  />
                  <Route
                    path="/saml"
                    element={<LoginFirebaseSAML organisationLogin={false} />}
                  />
                  <Route
                    path="/register-organization-sso"
                    element={<LoginFirebaseSAML organisationLogin />}
                  />
                </>
              )}
              <Route path="/registersaml" element={<RegisterSAML />} />

              {/* Private routes Admin */}
              <Route
                path="/admin/users"
                element={<PrivateRouteAdmin component={ManageUsers} />}
              />
              {/* -- Admin User Pages START -- */}
              <Route
                path="/admin/user/:userName/profile"
                element={<PrivateRouteAdmin component={AdminUserProfile} />}
              />
              <Route
                path="/admin/user/:userName/transcriptions"
                element={
                  <PrivateRouteAdmin component={AdminUserTranscriptions} />
                }
              />
              <Route
                path="/admin/user/:userName/transcription/:recordId"
                element={
                  <PrivateRouteAdmin component={AdminUserTranscriptionDetail} />
                }
              />
              <Route
                path="/admin/user/:userName/transactions"
                element={
                  <PrivateRouteAdmin component={AdminUserTransactions} />
                }
              />
              <Route
                path="/admin/user/:userName/invoices"
                element={<PrivateRouteAdmin component={AdminUserInvoices} />}
              />
              <Route
                path="/admin/user/:userName/donate"
                element={<PrivateRouteAdmin component={AdminUserDonate} />}
              />
              {/* -- Admin User Pages END -- */}

              <Route
                path="/admin/add/user"
                element={<PrivateRouteAdmin component={AddUser} />}
              />
              <Route
                path="/admin/invoices"
                element={<PrivateRouteAdmin component={AdminInvoices} />}
              />
              <Route
                path="/admin/organizations"
                element={
                  <PrivateRouteAdmin component={AdminOrganizationsList} />
                }
              />
              <Route
                path="/admin/organizations/organization/:id"
                element={<PrivateRouteAdmin component={AdminOrganization} />}
              />
              <Route
                path="/admin/kalturaTasks"
                element={<PrivateRouteAdmin component={KalturaTasks} />}
              />

              {/* Private routes User */}
              <Route
                path="/redeem"
                element={<PrivateRoute component={MyFiles} />}
              />
              <Route
                path="/records"
                element={<PrivateRoute component={MyFiles} />}
              />
              <Route
                path="/account"
                element={<PrivateRoute component={Account} />}
              />
              <Route
                path="/upload"
                element={<PrivateRoute component={Uploads} />}
              />
              <Route
                path="/billing"
                element={<PrivateRoute component={Billing} />}
              />
              <Route
                path="/invoices"
                element={<PrivateRoute component={Invoices} />}
              />
              <Route
                path="/organization"
                element={<PrivateRoute component={Organization} />}
              />
              <Route path="/plan" element={<PrivateRoute component={Plan} />} />
              <Route
                path="/appsumoplan"
                element={<PrivateRoute component={AppSumoPlan} />}
              />
              <Route
                path="/integrations"
                element={<PrivateRoute component={Integration} />}
              />

              {/* No match */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </BrowserRouter>

        {/* Global dialogs */}
        <RedeemCouponDialog />
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
